function Magnifier (baseDoom) {
    this.baseDoom = baseDoom
}

Magnifier.sizeX = 27
Magnifier.sizeY = 27
Magnifier.prototype = {

    constructor: Magnifier,

    show: function () {
        if (!this.canvas) {
            this.canvas = document.createElement('canvas')
            this.canvas.id = 'mag_' + Math.floor(Math.random() * 999999)
            this.context = this.canvas.getContext('2d')
            var s = this.canvas.style
            s.position = 'absolute'
            s.zIndex = '999999'
            s.backgroundColor = '#ccc'
            s.border = 'solid 1px white'
            s.top = '0px'
            s.left = '0px'
            s.pointerEvents = 'none'
            s.webkitTransform = 'scaleY(-1)'
            s.MozTransform = 'scaleY(-1)'
            s.msTransform = 'scaleY(-1)'
            s.transform = 'scaleY(-1)'
            s.OTransform = 'scaleY(-1)'
            this.crosshair = new window.Image()
            this.crosshair.src = 'img/crosshair.png'
            this.baseDoom.appendChild(this.canvas)
        }
        this.visible = true
        this.canvas.style.visibility = 'visible'
    },
    dispose: function () {
        if (this.canvas) {
            var pe = this.canvas.parentElement
            if (pe) {
                pe.removeChild(this.canvas)
            }

            if (this.imageData) {
                this.imageData = null
            }

            this.canvas = null
            this.context = null
        }
    },
    hide: function () {
        if (this.canvas) {
            this.canvas.style.visibility = 'hidden'
        }
        this.visible = false
    },
    createImageData: function (wid, heg) {
        if (!this.context) {
        // console.error("Magnifier is not started yet");
            return null
        }

        this.imageData = null

        this.width = wid
        this.height = heg
        this.imageData = this.context.createImageData(wid, heg)
        return this.imageData
    },

    renderImage: function (panogl, xCoord, yCoord) {
        if (!this.imageData) {
            this.createImageData(Magnifier.sizeX, Magnifier.sizeY)
        }

        var idata = this.imageData

        var width = this.width
        var height = this.height
        var gl = panogl.getRenderer().context
        var pixels = new Uint8Array(width * height * 4)

        gl.readPixels(xCoord - (width / 2), (panogl.contentDV.clientHeight - yCoord) - (height / 2), width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels)

        for (var i = 0; i < pixels.length; i += 4) {
            idata.data[i] = pixels[i] // red
            idata.data[i + 1] = pixels[i + 1] // green
            idata.data[i + 2] = pixels[i + 2] // blue
            idata.data[i + 3] = pixels[i + 3] // alpha
        }

        this.render(idata, width, height, xCoord, yCoord)
    },
    render: function (imageData, imageWidth, imageHeight, posX, posY) {
        if (!this.canvas) return

        this.canvas.width = imageWidth
        this.canvas.height = imageHeight
        var ctx = this.context
        ctx.imageSmoothingEnabled = false
        ctx.clearRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight)

        ctx.putImageData(imageData, 0, 0)

        var ch = this.crosshair
        ctx.drawImage(ch, (imageWidth - ch.width) / 2, (imageHeight - (ch.height + 1)) / 2)

        var w = imageWidth * 5
        var h = imageHeight * 5

        this.canvas.style.width = w + 'px'
        this.canvas.style.width = h + 'px'

        this.canvas.style.left = (posX - (w * 0.5)) + 'px'
        this.canvas.style.top = (posY - (h + 15)) + 'px'
    }
}

export {Magnifier}
