import { Tool } from './Tool'
import { movingPointCreator } from './EventCreator'
import GEOM_TYPES from './GeomTypes'

function RoadTool () {
    Tool.apply(this, arguments)
    this.type = GEOM_TYPES.RoadLine
}

RoadTool.prototype = Object.assign(Object.create(Tool.prototype),
    {
        constructor: RoadTool,
        startDrawing: function () {
            this._mousePos = new THREE.Vector3()
            this._raycasterForMouse = new THREE.Raycaster()
            Tool.prototype.startDrawing.apply(this, [3])
        },
        stopDrawing: function () {
            Tool.prototype.stopDrawing.apply(this, arguments)
        },
        onMouseDownForMiddleClick: function (e) {

        },
        groundMove: function (e) {
            Tool.prototype.groundMove.apply(this, arguments)

            if (this.scalable.activeGeom.canDraw) {
                this.enableClick()
            } else {
                this.disableClick()
            }

            if (this.counter < 2) {
                this.throwEvent(movingPointCreator(e.lon, e.lat, e.alt))
            } else {
                var loc = this.getRightPosition(e)
                this.throwEvent(movingPointCreator(loc.lon, loc.lat, loc.alt))
            }
        },

        getRightPosition: function (e) {
            var dt = this.baseObject.getDrawingPlugin()
            var gdh = dt.getCurrentGeometry()

            var p1 = gdh.points[0]
            var p2 = gdh.points[1]

            var dlon = p1.lon - p2.lon
            var dlat = p1.lat - p2.lat
            var rad = Math.atan2(dlon, dlat)

            var dist = AnkaPanAPI.Utils.haversine(p2.lat, e.lat, p2.lon, e.lon)

            dlon = p2.lon - e.lon
            dlat = p2.lat - e.lat
            var lastRad = Math.atan2(dlon, dlat)
            var sabitRad = rad / Math.PI * 180
            if (sabitRad < 0) sabitRad += 360

            lastRad = lastRad / 180 * Math.PI

            if (lastRad < 0) lastRad += 360

            lastRad -= sabitRad

            if (lastRad < 0) lastRad += 360

            lastRad %= 360

            let Px = e.lon
            let Py = e.lat

            var Ax = gdh.points[0].lon
            var Ay = gdh.points[0].lat

            var Bx = gdh.points[1].lon
            var By = gdh.points[1].lat

            let d = (Px - Ax) * (By - Ay) - (Py - Ay) * (Bx - Ax)

            var rightAngle = 90
            if (d > 0) {
                rightAngle = -90
            }

            var loc = AnkaPanAPI.Utils.destinationPoint(p2.lat, p2.lon, dist, (rad / Math.PI * 180) + rightAngle)
            loc.alt = p2.alt

            return loc
        },

        onMouseMove: function (e) {
            Tool.prototype.onMouseMove.apply(this, arguments)
        },

        contextClick: function (e) {
            this.stopDrawing(this.counter === 3)
        },

        onGroundLeave: function () {
            Tool.prototype.onGroundLeave.apply(this, arguments)
        },
        onGroundClick: function (e) {
            if (this.clickDisabled) return
            if (this.counter < 2) {
                this.raisePointCount()
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: e.lon, lat: e.lat, alt: e.alt })
            } else if (this.counter === 2) {
                var loc = this.getRightPosition(e)
                this.raisePointCount()
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: loc.lon, lat: loc.lat, alt: loc.alt })
                this.stopDrawing(true)
            }
        },

        onMouseDown: function (e) {
            this.mouseDownX = e.offsetX
            this.mouseDownY = e.offsetY
            this.isMoved = false
            this._isMouseDown = true
        },

        domClick: function (e) {
        }

    })

export { RoadTool }
