import { Tool } from './Tool'
import { movingPointCreator } from './EventCreator'
import GEOM_TYPES from './GeomTypes'

function GridLineTool () {
    Tool.apply(this, arguments)
    this.type = GEOM_TYPES.Line
}

GridLineTool.prototype = Object.assign(Object.create(Tool.prototype),
    {
        constructor: GridLineTool,
        startDrawing: function () {
            Tool.prototype.startDrawing.apply(this, [2])
        },

        stopDrawing: function () {
            Tool.prototype.stopDrawing.apply(this, arguments)
            this.drawingMesh = null
        },
        onMouseDownForMiddleClick: function (e) {

        },

        groundMove: function (e) {
            Tool.prototype.groundMove.apply(this, arguments)
            if (this.scalable.activeGeom.canDraw) {
                this.enableClick()
            } else {
                this.disableClick()
            }

            this.throwEvent(movingPointCreator(e.lon, e.lat, e.alt, this.scalable.activeGeom))
        },

        contextClick: function (e) {
            this.stopDrawing(this.scalable.activeGeom.points.length >= 2)
        },

        onGroundLeave: function () {
            Tool.prototype.onGroundLeave.apply(this, arguments)
        },
        onGroundClick: function (e) {
            if (this.clickDisabled) return

            this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: e.lon, lat: e.lat, alt: e.alt })
            this.raisePointCount()

            if (this.pointcount > -1 && this.counter >= this.pointcount) {
                this.stopDrawing(true)
            }
        }

    })

export { GridLineTool }
