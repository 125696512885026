import { Tool } from './Tool'
import GEOM_TYPES from './GeomTypes'

function PointTool (panogl, drawPlugin) {
    this.drawPlugin = drawPlugin
    Tool.apply(this, arguments)
    this.type = GEOM_TYPES.Point
}

PointTool.prototype = Object.assign(Object.create(Tool.prototype),
    {
        constructor: PointTool,
        startDrawing: function () {
            Tool.prototype.startDrawing.apply(this, [1])
        },
        onGroundClick: function (e) {
            if (this.clickDisabled) return

            this.counter++
            if (this.scalable) {
                // var alt = this.scalable.getCurrentAltitude();
                this.throwEvent({ type: Tool.STATUS_CHANGE, status: 'POINT_ADDED', lon: e.lon, lat: e.lat, alt: e.alt })
            }

            if (this.pointcount > 0 && this.counter >= this.pointcount) {
                this.stopDrawing(true)
            }
        },
        contextClick: function (e) {
            this.stopDrawing(false)
        }

    })

export { PointTool }
