
const GEOM_TYPES = {
    Polygon: 'Polygon',
    HLine: 'HLine',
    Line: 'Line',
    Point: 'Point',
    RoadLine: 'RoadLine'
}

export default GEOM_TYPES
