import GEOM_TYPES from './GeomTypes'

SGridWall.isMultiGeom = false
function SGridWall (scalable, geomdatholder) {
    AnkaScalable.SGeom.apply(this, arguments)
    this.points = []
    this.type = GEOM_TYPES.Line

    if (!SGridWall.__gridTexture) {
        var tloader = new THREE.TextureLoader()
        var t = tloader.load('img/draw/grid.png')
        t.wrapS = THREE.RepeatWrapping
        t.wrapT = THREE.RepeatWrapping
        SGridWall.__gridTexture = t
    }

    this.gridTexture = SGridWall.__gridTexture

    this.canDraw = true
    this.scalable = scalable
    this.softText = scalable.softText
    this.opacity = 0.7

    this._lineColor = new THREE.Color(1, 0, 1)
    this.material = new THREE.LineBasicMaterial({
        color: this._lineColor,
        linewidth: 1,
        depthTest: true,
        transparent: true,
        depthWrite: false
    })

    var vertexCount = 100

    this.createLine(vertexCount)
}

SGridWall.prototype = Object.assign(Object.create(AnkaScalable.SGeom.prototype),
    {
        constructor: SGridWall,

        reset: function () {
            // this.lookAt(new THREE.Vector3(0,this.position.y,0));
        },

        createLine: function (vertexCount) {
            if (this.line) {
                this.line.geometry.dispose()
                if (this.line.parent) {
                    this.line.parent.remove(this.line)
                }

                if (this.enabled) {
                    this.line.setClickable(false)
                }
            }

            var geom = new THREE.BufferGeometry()
            var positions = new Float32Array(vertexCount * 3) // 3 vertices per point
            geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))
            this.line = new THREE.Line(geom, this.material)
            this.line.name = 'SGridWallLine'
            this.line.frustumCulled = false
            this.add(this.line)

            if (this.enabled) {
                this.enabled = false
                this.enable(this.panogl)
            }
        },

        enable: function (panogl) {
            if (!this.enabled) {
                this.openGeom = true
                this.panogl = panogl
                this.canDraw = true
                this.enabled = true
            }
        },
        stopDraw: function () {
            this.openGeom = false
            this.dynamicPoint = null
            // disappear bug otherwise true
            this.line.frustumCulled = false
            var ar = this.line.geometry.attributes.position.array
            var index = -1
            for (var i = 0; i < ar.length; i++) {
                if (isNaN(ar[i])) {
                    ar[i] = 0
                    index = i
                }
            }

            if (index > -1) this.line.geometry.setDrawRange(0, Math.ceil(index / 3))
            this.line.geometry.computeBoundingSphere()

            this.setLabel()
        },

        setLabel: function () {

        },

        setDynamicPoint: function (p) {
            if (this.points.length > 0) {
                this.dynamicPoint = {lon: p.lon, lat: p.lat, alt: p.alt}
            }
        },

        setPoints: function (points) {
            this.points = points.concat()
        },

        setData: function (points, atts) {
            this.attributes = atts
            this.points.length = 0
            for (var i = 0; i < points.length; i++) {
                this.points.push(points[i])
            }
        },

        addPoint: function (lon, lat, alt) {
            this.points.push({lon: lon, lat: lat, alt: alt})
        },

        updateStyle: function () {
            if (this.__layer) {
                var style = this.__layer.getStyle()
                this.line.material.color = new THREE.Color(style.lineColor)
                this.line.material.opacity = style.lineOpacity
            }
        },

        update: function () {
            var sp = this.points
            var vertexCount = sp.length
            var geom = this.line.geometry
            var par = geom.attributes.position.array

            if (this.dynamicPoint) {
                vertexCount++
            }

            if ((par.length / 3) !== vertexCount) {
                this.createLine(vertexCount)
                geom = this.line.geometry
                par = geom.attributes.position.array
            }

            var contour = []
            var index = 0
            var firstPoint = null
            for (var i = 0; i < sp.length; i++) {
                let p = sp[i]
                let pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

                if (i === 0) {
                    firstPoint = pp
                }

                let x = pp.x// - firstPoint.x;
                let y = pp.y// - firstPoint.y;
                let z = pp.z// - firstPoint.z;

                par[index++] = x
                par[index++] = y
                par[index++] = z

                contour.push({x: x, z: z, y: y, lon: p.lon, lat: p.lat, alt: p.alt})
            }

            if (!firstPoint) {
                return
            }

            if (this.dynamicPoint) {
                let p = this.dynamicPoint
                let pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

                let yi = sp.length * 3

                let x = pp.x// - firstPoint.x;
                let y = pp.y// - firstPoint.y;
                let z = pp.z// - firstPoint.z;

                par[yi++] = x
                par[yi++] = y
                par[yi++] = z

                contour.push({x: x, z: z, y: y})
                geom.setDrawRange(0, sp.length + 1)
            } else {
                geom.setDrawRange(0, sp.length)
            }

            // this.position.set(firstPoint.x, firstPoint.y,firstPoint.z);
            this.position.set(0, 0, 0)

            geom.attributes.position.needsUpdate = true
            if (this.grid) {
                this.grid.parent.remove(this.grid)
                this.grid.geometry.dispose()
                this.grid.material.dispose()
                this.panogl.removeWall(this.grid)
                this.grid = null
            }

            if (contour.length > 1) {
                var wallgeom = new AnkaScalable.WallGeometry(contour)
                var mat = new THREE.MeshBasicMaterial({map: this.gridTexture, transparent: true, opacity: 1, side: THREE.DoubleSide, depthTest: false, depthWrite: false})
                this.grid = new THREE.Mesh(wallgeom, mat)
                this.add(this.grid)
                this.panogl.addWall(this.grid)
            }
        }, // update end

        disableAll: function () {
            if (this.parent) {
                this.parent.remove(this)
            }
            this.enabled = false
        },

        destroy: function () {
            this.disableAll()

            if (this.line) {
                if (this.line.geometry) {
                    this.line.geometry.dispose()
                }

                if (this.line.material) {
                    this.line.material.dispose()
                }
            }

            if (this.grid) {
                this.grid.parent.remove(this.grid)
                this.grid.geometry.dispose()
                this.grid.material.dispose()
                this.panogl.removeWall(this.grid)
                this.grid = null
            }

            if (this._clickMesh) {
                this._clickMesh.material.dispose()
                this._clickMesh.geometry.dispose()
            }
        }

    })

export {SGridWall}
